import {React} from 'react';
import {Segment, Button, Icon} from 'semantic-ui-react';
import {Box} from 'theme-ui';
import {toast} from 'react-toastify';
import { string } from 'prop-types';


export const Lookup = ({

    credential //the credential is for the reprint app proxy calls
                               
                                }) => {

    const rows = 25; // this is the amount of rows in the product area
    const collumns = 16; // this is the amount of collumns in the product area

    // if products aren't fitting this will have to grow
    let productList = [
        {key:"0",bootName: "", productID: "", content: null,},
        {key:"1",bootName: "", productID: "", content: null},
        {key:"2",bootName: "", productID: "", content: null},
        {key:"3",bootName: "", productID: "", content: null},
        {key:"4",bootName: "", productID: "", content: null},
        {key:"5",bootName: "", productID: "", content: null},
        {key:"6",bootName: "", productID: "", content: null},
        {key:"7",bootName: "", productID: "", content: null},
        {key:"8",bootName: "", productID: "", content: null},
        {key:"9",bootName: "", productID: "", content: null},
        {key:"10",bootName: "", productID: "", content: null},
        {key:"11",bootName: "", productID: "", content: null},
        {key:"12",bootName: "", productID: "", content: null},
        {key:"13",bootName: "", productID: "", content: null},
        {key:"14",bootName: "", productID: "", content: null},
        {key:"15",bootName: "", productID: "", content: null},
        {key:"16",bootName: "", productID: "", content: null},
        {key:"17",bootName: "", productID: "", content: null},
        {key:"18",bootName: "", productID: "", content: null},
        {key:"19",bootName: "", productID: "", content: null},
        {key:"20",bootName: "", productID: "", content: null},
        {key:"21",bootName: "", productID: "", content: null},
        {key:"22",bootName: "", productID: "", content: null},
        {key:"23",bootName: "", productID: "", content: null},
        {key:"24",bootName: "", productID: "", content: null},
        {key:"25",bootName: "", productID: "", content: null},
        {key:"26",bootName: "", productID: "", content: null},
        {key:"27",bootName: "", productID: "", content: null},
        {key:"28",bootName: "", productID: "", content: null},
        {key:"29",bootName: "", productID: "", content: null},
        {key:"30",bootName: "", productID: "", content: null},
        {key:"31",bootName: "", productID: "", content: null},
        {key:"32",bootName: "", productID: "", content: null},
        {key:"33",bootName: "", productID: "", content: null},
        {key:"34",bootName: "", productID: "", content: null},
        {key:"35",bootName: "", productID: "", content: null},
        {key:"36",bootName: "", productID: "", content: null},
        {key:"37",bootName: "", productID: "", content: null},
        {key:"38",bootName: "", productID: "", content: null},
        {key:"39",bootName: "", productID: "", content: null},
        {key:"40",bootName: "", productID: "", content: null},
        {key:"41",bootName: "", productID: "", content: null},
        {key:"42",bootName: "", productID: "", content: null},
        {key:"43",bootName: "", productID: "", content: null},
        {key:"44",bootName: "", productID: "", content: null},
        {key:"45",bootName: "", productID: "", content: null},
        {key:"46",bootName: "", productID: "", content: null},
        {key:"47",bootName: "", productID: "", content: null},
        {key:"48",bootName: "", productID: "", content: null},
        {key:"49",bootName: "", productID: "", content: null},
        {key:"50",bootName: "", productID: "", content: null},
        {key:"51",bootName: "", productID: "", content: null},
        {key:"52",bootName: "", productID: "", content: null},
        {key:"53",bootName: "", productID: "", content: null},
        {key:"54",bootName: "", productID: "", content: null},
        {key:"55",bootName: "", productID: "", content: null},
        {key:"56",bootName: "", productID: "", content: null},
        {key:"57",bootName: "", productID: "", content: null},
        {key:"58",bootName: "", productID: "", content: null},
        {key:"59",bootName: "", productID: "", content: null},
        {key:"60",bootName: "", productID: "", content: null},
        {key:"61",bootName: "", productID: "", content: null},
        {key:"62",bootName: "", productID: "", content: null},
        {key:"63",bootName: "", productID: "", content: null},
        {key:"64",bootName: "", productID: "", content: null},
        {key:"65",bootName: "", productID: "", content: null},
        {key:"66",bootName: "", productID: "", content: null},
        {key:"67",bootName: "", productID: "", content: null},
        {key:"68",bootName: "", productID: "", content: null},
        {key:"69",bootName: "", productID: "", content: null},
        {key:"70",bootName: "", productID: "", content: null},
        {key:"71",bootName: "", productID: "", content: null},
        {key:"72",bootName: "", productID: "", content: null},
        {key:"73",bootName: "", productID: "", content: null},
        {key:"74",bootName: "", productID: "", content: null},
        {key:"75",bootName: "", productID: "", content: null},
        {key:"76",bootName: "", productID: "", content: null},
        {key:"77",bootName: "", productID: "", content: null},
        {key:"78",bootName: "", productID: "", content: null},
        {key:"79",bootName: "", productID: "", content: null},
        {key:"80",bootName: "", productID: "", content: null},
        {key:"81",bootName: "", productID: "", content: null},
        {key:"82",bootName: "", productID: "", content: null},
        {key:"83",bootName: "", productID: "", content: null},
        {key:"84",bootName: "", productID: "", content: null},
        {key:"85",bootName: "", productID: "", content: null},
        {key:"86",bootName: "", productID: "", content: null},
        {key:"87",bootName: "", productID: "", content: null},
        {key:"88",bootName: "", productID: "", content: null},
        {key:"89",bootName: "", productID: "", content: null},
        {key:"90",bootName: "", productID: "", content: null},
        {key:"91",bootName: "", productID: "", content: null},
        {key:"92",bootName: "", productID: "", content: null},
        {key:"93",bootName: "", productID: "", content: null},
        {key:"94",bootName: "", productID: "", content: null},
        {key:"95",bootName: "", productID: "", content: null},
        {key:"96",bootName: "", productID: "", content: null},
        {key:"97",bootName: "", productID: "", content: null},
        {key:"98",bootName: "", productID: "", content: null},
        {key:"99",bootName: "", productID: "", content: null},
        {key:"100",bootName: "", productID: "", content: null},
        {key:"101",bootName: "", productID: "", content: null},
        {key:"102",bootName: "", productID: "", content: null},
        {key:"103",bootName: "", productID: "", content: null},
        {key:"104",bootName: "", productID: "", content: null},
        {key:"105",bootName: "", productID: "", content: null},
        {key:"106",bootName: "", productID: "", content: null},
        {key:"107",bootName: "", productID: "", content: null},
        {key:"108",bootName: "", productID: "", content: null},
        {key:"109",bootName: "", productID: "", content: null},
        {key:"110",bootName: "", productID: "", content: null},
        {key:"111",bootName: "", productID: "", content: null},
        {key:"112",bootName: "", productID: "", content: null},
        {key:"113",bootName: "", productID: "", content: null},
        {key:"114",bootName: "", productID: "", content: null},
        {key:"115",bootName: "", productID: "", content: null},
        {key:"116",bootName: "", productID: "", content: null},
        {key:"117",bootName: "", productID: "", content: null},
        {key:"118",bootName: "", productID: "", content: null},
        {key:"119",bootName: "", productID: "", content: null},
        {key:"120",bootName: "", productID: "", content: null},
        {key:"121",bootName: "", productID: "", content: null},
        {key:"122",bootName: "", productID: "", content: null},
        {key:"123",bootName: "", productID: "", content: null},
        {key:"124",bootName: "", productID: "", content: null},
        {key:"125",bootName: "", productID: "", content: null},
        {key:"126",bootName: "", productID: "", content: null},
        {key:"127",bootName: "", productID: "", content: null},
        {key:"128",bootName: "", productID: "", content: null},
        {key:"129",bootName: "", productID: "", content: null},
        {key:"130",bootName: "", productID: "", content: null},
        {key:"131",bootName: "", productID: "", content: null},
        {key:"132",bootName: "", productID: "", content: null},
        {key:"133",bootName: "", productID: "", content: null},
        {key:"134",bootName: "", productID: "", content: null},
        {key:"135",bootName: "", productID: "", content: null},
        {key:"136",bootName: "", productID: "", content: null},
        {key:"137",bootName: "", productID: "", content: null},
        {key:"138",bootName: "", productID: "", content: null},
        {key:"139",bootName: "", productID: "", content: null},
        {key:"140",bootName: "", productID: "", content: null},
        {key:"141",bootName: "", productID: "", content: null},
        {key:"142",bootName: "", productID: "", content: null},
        {key:"143",bootName: "", productID: "", content: null},
        {key:"144",bootName: "", productID: "", content: null},
        {key:"145",bootName: "", productID: "", content: null},
        {key:"146",bootName: "", productID: "", content: null},
        {key:"147",bootName: "", productID: "", content: null},
        {key:"148",bootName: "", productID: "", content: null},
        {key:"149",bootName: "", productID: "", content: null},
        {key:"150",bootName: "", productID: "", content: null},
        {key:"151",bootName: "", productID: "", content: null},
        {key:"152",bootName: "", productID: "", content: null},
        {key:"153",bootName: "", productID: "", content: null},
        {key:"154",bootName: "", productID: "", content: null},
        {key:"155",bootName: "", productID: "", content: null},
        {key:"156",bootName: "", productID: "", content: null},
        {key:"157",bootName: "", productID: "", content: null},
        {key:"158",bootName: "", productID: "", content: null},
        {key:"159",bootName: "", productID: "", content: null},
        {key:"160",bootName: "", productID: "", content: null},
        {key:"161",bootName: "", productID: "", content: null},
        {key:"162",bootName: "", productID: "", content: null},
        {key:"163",bootName: "", productID: "", content: null},
        {key:"164",bootName: "", productID: "", content: null},
        {key:"165",bootName: "", productID: "", content: null},
        {key:"166",bootName: "", productID: "", content: null},
        {key:"167",bootName: "", productID: "", content: null},
        {key:"168",bootName: "", productID: "", content: null},
        {key:"169",bootName: "", productID: "", content: null},
        {key:"170",bootName: "", productID: "", content: null},
        {key:"171",bootName: "", productID: "", content: null},
        {key:"172",bootName: "", productID: "", content: null},
        {key:"173",bootName: "", productID: "", content: null},
        {key:"174",bootName: "", productID: "", content: null},
        {key:"175",bootName: "", productID: "", content: null},
        {key:"176",bootName: "", productID: "", content: null},
        {key:"177",bootName: "", productID: "", content: null},
        {key:"178",bootName: "", productID: "", content: null},
        {key:"179",bootName: "", productID: "", content: null},
        {key:"180",bootName: "", productID: "", content: null},
        {key:"181",bootName: "", productID: "", content: null},
        {key:"182",bootName: "", productID: "", content: null},
        {key:"183",bootName: "", productID: "", content: null},
        {key:"184",bootName: "", productID: "", content: null},
        {key:"185",bootName: "", productID: "", content: null},
        {key:"186",bootName: "", productID: "", content: null},
        {key:"187",bootName: "", productID: "", content: null},
        {key:"188",bootName: "", productID: "", content: null},
        {key:"189",bootName: "", productID: "", content: null},
        {key:"190",bootName: "", productID: "", content: null},
        {key:"191",bootName: "", productID: "", content: null},
        {key:"192",bootName: "", productID: "", content: null},
        {key:"193",bootName: "", productID: "", content: null},
        {key:"194",bootName: "", productID: "", content: null},
        {key:"195",bootName: "", productID: "", content: null},
        {key:"196",bootName: "", productID: "", content: null},
        {key:"197",bootName: "", productID: "", content: null},
        {key:"198",bootName: "", productID: "", content: null},
        {key:"199",bootName: "", productID: "", content: null},
        {key:"200",bootName: "", productID: "", content: null},
        {key:"201",bootName: "", productID: "", content: null},
        {key:"202",bootName: "", productID: "", content: null},
        {key:"203",bootName: "", productID: "", content: null},
        {key:"204",bootName: "", productID: "", content: null},
        {key:"205",bootName: "", productID: "", content: null},
        {key:"206",bootName: "", productID: "", content: null},
        {key:"207",bootName: "", productID: "", content: null},
        {key:"208",bootName: "", productID: "", content: null},
        {key:"209",bootName: "", productID: "", content: null},
        {key:"210",bootName: "", productID: "", content: null},
        {key:"211",bootName: "", productID: "", content: null},
        {key:"212",bootName: "", productID: "", content: null},
        {key:"213",bootName: "", productID: "", content: null},
        {key:"214",bootName: "", productID: "", content: null},
        {key:"215",bootName: "", productID: "", content: null},
        {key:"216",bootName: "", productID: "", content: null},
        {key:"217",bootName: "", productID: "", content: null},
        {key:"218",bootName: "", productID: "", content: null},
        {key:"219",bootName: "", productID: "", content: null},
        {key:"220",bootName: "", productID: "", content: null},
        {key:"221",bootName: "", productID: "", content: null},
        {key:"222",bootName: "", productID: "", content: null},
        {key:"223",bootName: "", productID: "", content: null},
        {key:"224",bootName: "", productID: "", content: null},
        {key:"225",bootName: "", productID: "", content: null},
        {key:"226",bootName: "", productID: "", content: null},
        {key:"227",bootName: "", productID: "", content: null},
        {key:"228",bootName: "", productID: "", content: null},
        {key:"229",bootName: "", productID: "", content: null},
        {key:"230",bootName: "", productID: "", content: null},
        {key:"231",bootName: "", productID: "", content: null},
        {key:"232",bootName: "", productID: "", content: null},
        {key:"233",bootName: "", productID: "", content: null},
        {key:"234",bootName: "", productID: "", content: null},
        {key:"235",bootName: "", productID: "", content: null},
        {key:"236",bootName: "", productID: "", content: null},
        {key:"237",bootName: "", productID: "", content: null},
        {key:"238",bootName: "", productID: "", content: null},
        {key:"239",bootName: "", productID: "", content: null},
        {key:"240",bootName: "", productID: "", content: null},
        {key:"241",bootName: "", productID: "", content: null},
        {key:"242",bootName: "", productID: "", content: null},
        {key:"243",bootName: "", productID: "", content: null},
        {key:"244",bootName: "", productID: "", content: null},
        {key:"245",bootName: "", productID: "", content: null},
        {key:"246",bootName: "", productID: "", content: null},
        {key:"247",bootName: "", productID: "", content: null},
        {key:"248",bootName: "", productID: "", content: null},
        {key:"249",bootName: "", productID: "", content: null},
        {key:"250",bootName: "", productID: "", content: null},
        {key:"251",bootName: "", productID: "", content: null},
        {key:"252",bootName: "", productID: "", content: null},
        {key:"253",bootName: "", productID: "", content: null},
        {key:"254",bootName: "", productID: "", content: null},
        {key:"255",bootName: "", productID: "", content: null},
        {key:"256",bootName: "", productID: "", content: null},
        {key:"257",bootName: "", productID: "", content: null},
        {key:"258",bootName: "", productID: "", content: null},
        {key:"259",bootName: "", productID: "", content: null},
        {key:"260",bootName: "", productID: "", content: null},
        {key:"261",bootName: "", productID: "", content: null},
        {key:"262",bootName: "", productID: "", content: null},
        {key:"263",bootName: "", productID: "", content: null},
        {key:"264",bootName: "", productID: "", content: null},
        {key:"265",bootName: "", productID: "", content: null},
        {key:"266",bootName: "", productID: "", content: null},
        {key:"267",bootName: "", productID: "", content: null},
        {key:"268",bootName: "", productID: "", content: null},
        {key:"269",bootName: "", productID: "", content: null},
        {key:"270",bootName: "", productID: "", content: null},
        {key:"271",bootName: "", productID: "", content: null},
        {key:"272",bootName: "", productID: "", content: null},
        {key:"273",bootName: "", productID: "", content: null},
        {key:"274",bootName: "", productID: "", content: null},
        {key:"275",bootName: "", productID: "", content: null},
        {key:"276",bootName: "", productID: "", content: null},
        {key:"277",bootName: "", productID: "", content: null},
        {key:"278",bootName: "", productID: "", content: null},
        {key:"279",bootName: "", productID: "", content: null},
        {key:"280",bootName: "", productID: "", content: null},
        {key:"281",bootName: "", productID: "", content: null},
        {key:"282",bootName: "", productID: "", content: null},
        {key:"283",bootName: "", productID: "", content: null},
        {key:"284",bootName: "", productID: "", content: null},
        {key:"285",bootName: "", productID: "", content: null},
        {key:"286",bootName: "", productID: "", content: null},
        {key:"287",bootName: "", productID: "", content: null},
        {key:"288",bootName: "", productID: "", content: null},
        {key:"289",bootName: "", productID: "", content: null},
        {key:"290",bootName: "", productID: "", content: null},
        {key:"291",bootName: "", productID: "", content: null},
        {key:"292",bootName: "", productID: "", content: null},
        {key:"293",bootName: "", productID: "", content: null},
        {key:"294",bootName: "", productID: "", content: null},
        {key:"295",bootName: "", productID: "", content: null},
        {key:"296",bootName: "", productID: "", content: null},
        {key:"297",bootName: "", productID: "", content: null},
        {key:"298",bootName: "", productID: "", content: null},
        {key:"299",bootName: "", productID: "", content: null},
        {key:"300",bootName: "", productID: "", content: null},
        {key:"301",bootName: "", productID: "", content: null},
        {key:"302",bootName: "", productID: "", content: null},
        {key:"303",bootName: "", productID: "", content: null},
        {key:"304",bootName: "", productID: "", content: null},
        {key:"305",bootName: "", productID: "", content: null},
        {key:"306",bootName: "", productID: "", content: null},
        {key:"307",bootName: "", productID: "", content: null},
        {key:"308",bootName: "", productID: "", content: null},
        {key:"309",bootName: "", productID: "", content: null},
        {key:"310",bootName: "", productID: "", content: null},
        {key:"311",bootName: "", productID: "", content: null},
        {key:"312",bootName: "", productID: "", content: null},
        {key:"313",bootName: "", productID: "", content: null},
        {key:"314",bootName: "", productID: "", content: null},
        {key:"315",bootName: "", productID: "", content: null},
        {key:"316",bootName: "", productID: "", content: null},
        {key:"317",bootName: "", productID: "", content: null},
        {key:"318",bootName: "", productID: "", content: null},
        {key:"319",bootName: "", productID: "", content: null},
    ]

    //this shows how many elements are in the product collumns
    let collumnAmmounts = [
        0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    ]


    let productDone = false;//this is for the asynchronous calls to productFill to know when the startUp is done that way it can display that start up is finished
    let productFillCount = 0;//this also helps with the asynchronous calls to product fill as only the last product fill will activate
    let firstStart = true;//this is used by startUp for things that only happen in the first use of startUp

    //these are for the moving with keys function so that the location information is stored somewhere of the selected tile 
    let sizePicked = false;
    let productPicked = false;
    let sizePickedX = 0;
    let sizePickedY = 0;
    let productPickedIndex = 0;

    let cellName = ""; //used instead of returning the name of the size cell 
    let cityChoice = 0;
    let cities = 7;

    //this function grabs all the information from shopify and stores it, as well as other little things involving that
    const startUp = async () => {
        if(firstStart)
        {
            //adds the listener for move and changes the dataButton html
            window.addEventListener('keydown', (event) => {mover(event)});
            firstStart = false;
            document.getElementById('dataButton').innerHTML = "Refresh Data";
        }
        else{
            if(productFillCount != 0  || !productDone)
            {
                return;
            }
        }
        productDone = false;
        collumnAmmounts = [
            0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
        ]
        clearBoard();//makes things easier to clear the board every time
        for(let x = 0; x < productList.length;x++)
        {
            document.getElementById("product" + String(x)).style.visibility = "hidden"; // because react won't let me do hidden in the style of the html returned
        }
        
        productFill();
    };

    //used by start up, this will take in a productId and grab the information on the variants from shopify, then grab the inventory items and change the corrosponding variant to show inventory only from spokane valley location
    const productFill = async () => {

        //fill the productList item with what is needed

        //grab the information from shopify for this specific product
        console.log("Get all products")
        let orderInfoReturn = await fetch(
            'https://jkmkd6hpql.execute-api.us-east-2.amazonaws.com/Prod/LoadStockFromDB',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                    'Access-Control-Allow-Methods': '*',
                    'Access-Control-Allow-Origin': '*',
                },
                body: "allID",
            }
        );
        let orderJson = await orderInfoReturn.json();
        
        console.log(orderJson.products[0].PK)
        console.log(orderJson);


        for (let i = 0; i < orderJson.products.length; i++) {
            let collumnChoice = -1;
        let currTitle = orderJson.products[i].Title;

        if (currTitle.includes("Restock")) {
            continue
        }

        if(currTitle.includes("Fire")){
            collumnChoice = 0;
        }else if(currTitle.includes("Superduty") || (currTitle.includes("Honey") && currTitle.includes("Bison"))){
            collumnChoice = 2;
        }else if(currTitle.includes("O.T.")){
            collumnChoice = 4;
        }else if(currTitle.includes("Forefront")){
            collumnChoice = 6;
        }else if(currTitle.includes("300")){
            collumnChoice = 8;
        }else if(currTitle.includes("Climber")){
            collumnChoice = 10;
        }else if(currTitle.includes("Arctic")){
            collumnChoice = 12;
        }else if(currTitle.includes("Harvester")){
            collumnChoice = 14;
        } else if (currTitle.includes("Try-on")) {
            continue
        }

        if(collumnChoice == -1)
        {
            toast.error('Contact IT, program encountered product it can\'t deal with (' + currTitle + ')', {
            position: 'top-center',
            autoClose: 60000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
            });
            return;
        }
        if(currTitle.includes("Safety")){
            collumnChoice = collumnChoice + 1;
        }
        if(collumnAmmounts[collumnChoice] >= rows)
        {
            toast.error('Contact IT, (' + currTitle + ')' + 'would overfill the column space', {
                position: 'top-center',
                autoClose: 60000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
                });
            return;
        }
        

        let listIndex = collumnChoice + collumns * collumnAmmounts[collumnChoice];
        collumnAmmounts[collumnChoice] = collumnAmmounts[collumnChoice] + 1;




        productList = productList.map((item) => {

            if (item.key === String(listIndex)) {
              const updatedItem = {
                ...item,
                bootName: String(orderJson.products[i].Title),
                productID: String((orderJson.products[i].PK)),
                content: orderJson.products[i],
              };
      
              return updatedItem;
            }
      
            return item;
          });

        console.log(listIndex)

        let element1 = document.getElementById("product" + listIndex);
        element1.innerHTML = String(orderJson.products[i].Title);
        element1.style.visibility = 'visible';
        element1.style.borderRadius =  "5px";
        }
        
        toast.success('Finished Setup', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            
            });
            productDone = true;
            console.log(productList)
        await sortCollumns();
    };

    //this is called by the last productFill , and will sort the items within each collumn
    const sortCollumns = async () => {

        for(let col = 0; col < collumns;col++)
        {
            for(let baseRow = col; baseRow < rows * collumns; baseRow = baseRow + collumns)//has to go up by the ammount of collumns to go up directly one in the respective collumn
            //starts of on the column number 
            //less than rows times collumns basically will just stop once it has gone past the last true row
            {
                for(let currRow = baseRow + collumns; currRow < rows * collumns; currRow = currRow + collumns)
                {
                    compareProductsThenSwap(baseRow,currRow);
                }
            }
        }
        //go through each collumn
            //go through each row
                //go though all below in row
                //compare base row with below row
                //if below row greater than base row
                    //swap rows
    };

    //this is called by sortCollumns ,this will check if products should be swapped, and if they should it swaps them
    const compareProductsThenSwap = async (index0,index1) =>
    {
        //compares index0 and index1
        //if they index0 belongs below index1 they swap, otherwise nothing happens
        let title0 = productList[index0].bootName;
        let title1 = productList[index1].bootName;

        if(title0 == "" || title1 == "")
        {
            return;
        }
        let matchType = false;//it goes type then color in the sorting so if the type matches then we go to color

        let mainSort = [
            "Gray",
            "Bison",
            "X",
            "8\"",
            "2",
            " S ",
            "\"S\"",
            "(S)",
            "LTT",
            "Honey",
            "6\"",
            "Pro",
            "1",
        ]
        let otherSort = [
            "Mahogany",
            "Redwood",
            "Brown",
            "Black",
        ]

        for(let x = 0; x < mainSort.length;x++)
        {
            if(title0.includes(mainSort[x]))
            {
                if(title1.includes(mainSort[x])){
                    matchType = true;
                }
                else{
                    swap(index0,index1);
                    return;
                }
            }else if (title1.includes(mainSort[x]))
            {
                return;
            }
        }

        for(let y = 0; y < otherSort.length;y++)
        {
            if(title0.includes(otherSort[y]))
            {
                if(title1.includes(otherSort[y])){

                }
                else{
                    swap(index0,index1);
                    return;
                }
            }else if (title1.includes(otherSort[y]))
            {
                return;
            }
            if(matchType){
                
            }
        }

    };

    //this is called by compareProductsThenSwap, this will swap to products in the product list and then update the display
    const swap = async (index0,index1) => {

        let saver = productList[Number(index0)]
        
        productList = productList.map((item) => {

            if (item.key === String(index0)) {
              const updatedItem = {
                ...item,
                bootName: productList[Number(index1)].bootName,
                productID: productList[Number(index1)].productID,
                content: productList[Number(index1)].content,
              };
      
              return updatedItem;
            }

            if (item.key === String(index1)) {
                const updatedItem = {
                  ...item,
                  bootName: saver.bootName,
                  productID: saver.productID,
                  content: saver.content,
                };
        
                return updatedItem;
              }
            return item;

          });

        let element1 = document.getElementById("product" + index0);
        element1.innerHTML = productList[index0].bootName;
        let element2 = document.getElementById("product" + index1);
        element2.innerHTML = productList[index1].bootName;

        
    };

    // resets the colors of the size chart and product table
    const clearBoard = () => {
        for(let x = 0;x < 27; x++)
        {
            for(let y = 0; y < 6; y++)
            {
                findCell(x,y);
                let cell = document.getElementById(cellName);
                cell.backgroundColor = "White";
                if(x > 0 && y > 0){
                    cell.style.backgroundColor = "Grey";
                    cell.innerHTML = "0"
                } else {
                    cell.style.backgroundColor = "White";
                }
            }
        }
        for(let x = 0; x < productList.length; x++)
        {
            let curr = productList[x];
            if(curr.content !=  null)
            {
                let productCell = document.getElementById("product"+ x);
                productCell.style.backgroundColor = "White";
            }
        }
        for(let x = 0; x < cities ;x++)
        {
            let cityCell = document.getElementById("city" + x);
            if(x == cityChoice)
            {
                cityCell.style.backgroundColor = "Green";
            }
            else{
                cityCell.style.backgroundColor = "White";
            }
        }
    };

    //turns coordinates into the correct cell name, that cell name is saved instead of returned
    const findCell = async (xNum, yNum) => {
        cellName = "cell"
        if(xNum == 0){
            cellName = cellName + "3p0";
        }else if(xNum == 1){
            cellName = cellName + "3p5";
        }else if(xNum == 2){
            cellName = cellName + "4p0";
        }else if(xNum == 3){
            cellName = cellName + "4p5";
        }else if(xNum == 4){
            cellName = cellName + "5p0";
        }else if(xNum == 5){
            cellName = cellName + "5p5";
        }else if(xNum == 6){
            cellName = cellName + "6p0";
        }else if(xNum == 7){
            cellName = cellName + "6p5";
        }else if(xNum == 8){
            cellName = cellName + "7p0";
        }else if(xNum == 9){
            cellName = cellName + "7p5";
        }else if(xNum == 10){
            cellName = cellName + "8p0";
        }else if(xNum == 11){
            cellName = cellName + "8p5";
        }else if(xNum == 12){
            cellName = cellName + "9p0";
        }else if(xNum == 13){
            cellName = cellName + "9p5";
        }else if(xNum == 14){
            cellName = cellName + "10p0";
        }else if(xNum == 15){
            cellName = cellName + "10p5";
        }else if(xNum == 16){
            cellName = cellName + "11p0";
        }else if(xNum == 17){
            cellName = cellName + "11p5";
        }else if(xNum == 18){
            cellName = cellName + "12p0";
        }else if(xNum == 19){
            cellName = cellName + "12p5";
        }else if(xNum == 20){
            cellName = cellName + "13p0";
        }else if(xNum == 21){
            cellName = cellName + "13p5";
        }else if(xNum == 22){
            cellName = cellName + "14p0";
        }else if(xNum == 23){
            cellName = cellName + "14p5";
        }else if(xNum == 24){
            cellName = cellName + "15p0";
        }else if(xNum == 25){
            cellName = cellName + "15p5";
        }else if(xNum == 26){
            cellName = cellName + "16p0";
        }
        
        if(yNum == 0){
            cellName = cellName + "N";
        }else if(yNum == 1){
            cellName = cellName + "D";
        }else if(yNum == 2){
            cellName = cellName + "E";
        }else if(yNum == 3){
            cellName = cellName + "2E";
        }else if(yNum == 4){
            cellName = cellName + "3E";
        }else if(yNum == 5){
            cellName = cellName + "5E";
        }
    };

    //turns coordinates into the correct cell name, that cell name is saved instead of returned
    const findCellFromCell = async (xNum, yNum) => {
        xNum = xNum - 1;
        cellName = "cell"
        if(xNum == 0){
            cellName = cellName + "3p0";
        }else if(xNum == 1){
            cellName = cellName + "3p5";
        }else if(xNum == 2){
            cellName = cellName + "4p0";
        }else if(xNum == 3){
            cellName = cellName + "4p5";
        }else if(xNum == 4){
            cellName = cellName + "5p0";
        }else if(xNum == 5){
            cellName = cellName + "5p5";
        }else if(xNum == 6){
            cellName = cellName + "6p0";
        }else if(xNum == 7){
            cellName = cellName + "6p5";
        }else if(xNum == 8){
            cellName = cellName + "7p0";
        }else if(xNum == 9){
            cellName = cellName + "7p5";
        }else if(xNum == 10){
            cellName = cellName + "8p0";
        }else if(xNum == 11){
            cellName = cellName + "8p5";
        }else if(xNum == 12){
            cellName = cellName + "9p0";
        }else if(xNum == 13){
            cellName = cellName + "9p5";
        }else if(xNum == 14){
            cellName = cellName + "10p0";
        }else if(xNum == 15){
            cellName = cellName + "10p5";
        }else if(xNum == 16){
            cellName = cellName + "11p0";
        }else if(xNum == 17){
            cellName = cellName + "11p5";
        }else if(xNum == 18){
            cellName = cellName + "12p0";
        }else if(xNum == 19){
            cellName = cellName + "12p5";
        }else if(xNum == 20){
            cellName = cellName + "13p0";
        }else if(xNum == 21){
            cellName = cellName + "13p5";
        }else if(xNum == 22){
            cellName = cellName + "14p0";
        }else if(xNum == 23){
            cellName = cellName + "14p5";
        }else if(xNum == 24){
            cellName = cellName + "15p0";
        }else if(xNum == 25){
            cellName = cellName + "15p5";
        }else if(xNum == 26){
            cellName = cellName + "16p0";
        }
        
        if(yNum == 0){
            cellName = cellName + "N";
        }else if(yNum == 4){
            cellName = cellName + "D";
        }else if(yNum == 5){
            cellName = cellName + "E";
        }else if(yNum == 6){
            cellName = cellName + "2E";
        }else if(yNum == 7){
            cellName = cellName + "3E";
        }else if(yNum == 9){
            cellName = cellName + "5E";
        }
    };

    //this is what is called by the products when they are clicked
    const productClicked = async (productId) => {
        if(productFillCount != 0  || !productDone)
        {
            return;
        }
        fillInFromID(productId);
    };

    //gets called by productClicked
    const fillInFromID = async (productId) => {
        clearBoard();
        resetInnerHtml();
        sizePicked = false;
        productPicked = true;
        productPickedIndex = productId;

        console.log("Get all info on 1 product")
        let orderInfoReturn = await fetch(
            'https://jkmkd6hpql.execute-api.us-east-2.amazonaws.com/Prod/LoadStockFromDB',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                    'Access-Control-Allow-Methods': '*',
                    'Access-Control-Allow-Origin': '*',
                },
                body: "fromID" + productList[productId].content.PK,
            }
        );
        let orderJson = await orderInfoReturn.json();

        console.log(orderJson)
        let productCell = document.getElementById("product" + productId);
            productCell.style.backgroundColor = "Green";
        for(let x = 0; x < orderJson.product.Variants.length;x++)
        {
            let finder = "" + orderJson.product.Variants[x].option1;
            let helper = "cell"
            if(finder.length == 1 || finder.length == 2)
            {
                helper = helper + finder + "p0"
            }
            else{
                for(let y =0; y < finder.length;y++){
                    if(finder.charAt(y) == '.')
                    {
                        helper = helper + "p"
                    }
                    else{
                        helper = helper + finder.charAt(y)
                    }
                }
            }
            let option2fixer = orderJson.product.Variants[x].option2;
            if(option2fixer == "EE")
                option2fixer = "2E";

            finder = helper + option2fixer;
            let htmlChoice = "";

            let element1 = document.getElementById(finder);
            if(element1 == null)
            {

            }
            else{
                if(cityChoice == cities - 1)
                {
                    if(Number(orderJson.product.Variants[x].inventory_quantity) > 0){
                        element1.style.backgroundColor = "green";
                    }
                    if(Number(orderJson.product.Variants[x].inventory_quantity) == 0){
                        element1.style.backgroundColor = "white";
                    }
                    if(Number(orderJson.product.Variants[x].inventory_quantity) < 0){
                        element1.style.backgroundColor = "red";
                    }
                    htmlChoice = "" + orderJson.product.Variants[x].inventory_quantity;
                }
                else
                {

                    //collect from the data
                    let lilHelper = ""
                    //collect from the data
                    switch (cityChoice) {
                        case 0:
                            lilHelper = orderJson.product.Variants[x].SpokaneValInv
                            break
                        case 1:
                            lilHelper = orderJson.product.Variants[x].TriCitiesInv
                            break
                        case 2:
                            lilHelper = orderJson.product.Variants[x].PhoenixInv
                            break
                        case 3:
                            lilHelper = orderJson.product.Variants[x].ReddingInv
                            break
                        case 4:
                            lilHelper = orderJson.product.Variants[x].SpokaneVnv
                            break
                        case 5:
                            lilHelper = orderJson.product.Variants[x].BoiseInv
                            break
                    }
                    htmlChoice = "" + lilHelper;
                    if(lilHelper == "n" || lilHelper == "null")
                    {
                        element1.style.backgroundColor = "gray";
                        htmlChoice = "0";
                    }
                    else if(lilHelper > "0"){
                        element1.style.backgroundColor = "green";
                    }
                    else if(lilHelper == "0"){
                        element1.style.backgroundColor = "white";
                    }
                    else if(lilHelper < "0"){
                        element1.style.backgroundColor = "red";
                    }
                    
                }
                element1.innerHTML = htmlChoice;

            }
            
        }

    };

    //this is what is called by the sizes when they are clicked
    const sizeCellClicked = async (xNum, yNum) => {
        if(productFillCount != 0  || !productDone)
        {
            return;
        }
        //get sizes
        clearBoard();
        resetInnerHtml();

        sizePicked = true;
        productPicked = false;
        sizePickedX = xNum;
        sizePickedY = yNum;
        //findCell(xNum,0);
        //cell = document.getElementById(cellName);
        //cell.backgroundColor = "White";

        //findCell(0,yNum);
        //cell = document.getElementById(cellName);
        //cell.backgroundColor = "White";
    
        let variantIndex = 0;
        variantIndex = (variantIndex + yNum - 4) * 5;


        findCellFromCell(yNum,xNum);
        let cell = document.getElementById(cellName);
        cell.style.backgroundColor = "Green";

        findCellFromCell(1,xNum);
        cell = document.getElementById(cellName);
        cell.style.backgroundColor = "Green";

        findCellFromCell(yNum,0);
        cell = document.getElementById(cellName);
        cell.style.backgroundColor = "Green";


        let bootLength = (yNum * 0.5) + 2.5;
        let bootWidth = "";
        if(xNum == 4)
        {
            bootWidth = "D";
        }
        if(xNum == 5)
        {
            bootWidth = "E";
            variantIndex = variantIndex + 1;
        }
        if(xNum == 6)
        {
            bootWidth = "EE";
            variantIndex = variantIndex + 2;
        }
        if(xNum == 7)
        {
            bootWidth = "3E";
            variantIndex = variantIndex + 3;
        }
        if(xNum == 9)
        {
            bootWidth = "5E";
            variantIndex = variantIndex + 4;
        }

        let steelToeVariantIndex = variantIndex;
        if(steelToeVariantIndex == 84)
        {
            steelToeVariantIndex = -1;
        }
        if(variantIndex > 94)
        {
            steelToeVariantIndex -= 3;
        }
        else if(variantIndex > 89)
        {
            steelToeVariantIndex -= 2;
            if(steelToeVariantIndex == 92)
            {
                steelToeVariantIndex = -1;
            }
        }
        else if(variantIndex > 84)
        {
            steelToeVariantIndex -= 1;
            if(steelToeVariantIndex == 88)
            {
                steelToeVariantIndex = -1;
            }
        }
        
        console.log("Get all products with size")
        console.log(bootLength + " " + bootWidth)
        let orderInfoReturn = await fetch(
            'https://jkmkd6hpql.execute-api.us-east-2.amazonaws.com/Prod/LoadStockFromDB',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                    'Access-Control-Allow-Methods': '*',
                    'Access-Control-Allow-Origin': '*',
                },
                body: "fromSize " + bootLength + " " + bootWidth,
            }
        );
        let orderJson = await orderInfoReturn.json();

        console.log(orderJson.products.length);
        for (let i = 0; i < orderJson.products.length; i++) {
            for (let y = 0; y < productList.length; y++) {
                if(productList[y].productID == String(orderJson.products[i].PK)) {
                    console.log("Updating Item")
                    productList[y].content = orderJson.products[i]
                }
            }
        }

        console.log(productList)
        for(let productIndex = 0; productIndex < productList.length ; productIndex ++)
        {
            let product = productList[productIndex];
            if(product.bootName != "")
            {
                if(!(productList[productIndex].bootName.includes("safety") || productList[productIndex].bootName.includes("Safety")))
                {
                    let productAmount =  product.content.Variants[0];
                    if(productAmount == null)
                    {
                        
                    }
                    else{

                        //////////

                        if(cityChoice == cities - 1)
                        {
                            productAmount = productAmount.inventory_quantity;
                            if(productAmount > 0)
                            {
                                let element1 = document.getElementById("product" + productIndex);
                                element1.style.backgroundColor = 'green';
                                element1.innerHTML = element1.innerHTML +   "<span class='stock'> " + String(productAmount) + " in Stock</span>";
                            } 
                            else{
                                let element1 = document.getElementById("product" + productIndex);
                                element1.style.backgroundColor = 'white';
                            }
                        }
                        else
                        {
                            console.log("Product Amount " + productAmount)
                            let lilHelper = ""
                            //collect from the data
                            switch (cityChoice) {
                                case 0:
                                    lilHelper = productAmount.SpokaneValInv
                                    break
                                case 1:
                                    lilHelper = productAmount.TriCitiesInv
                                    break
                                case 2:
                                    lilHelper = productAmount.PhoenixInv
                                    break
                                case 3:
                                    lilHelper = productAmount.ReddingInv
                                    break
                                case 4:
                                    lilHelper = productAmount.SpokaneInv
                                    break
                                case 5:
                                    lilHelper = productAmount.BoiseInv
                                    break
                            }
        
        
                            console.log(lilHelper)
                            let element1 = document.getElementById("product" + productIndex);


                            if(lilHelper == "n")
                            {
                                element1.style.backgroundColor = "gray";
                            }
                            else if(lilHelper > "0"){
                                element1.style.backgroundColor = "green";
                                element1.innerHTML = element1.innerHTML +   "<span class='stock'> " + String(lilHelper) + " in Stock</span>";
                            }
                            else if(lilHelper == "0"){
                                element1.style.backgroundColor = "white";
                            }   
                        }
                    }
                }
                else{
                    if((productList[productIndex].bootName.includes("safety") || productList[productIndex].bootName.includes("Safety")))
                    {
                        let productAmount =  product.content.Variants[0];
                        if(productAmount == null)
                        {
                            
                        }
                        else{

                            //////////

                            if(cityChoice == cities - 1)
                            {
                                productAmount = productAmount.inventory_quantity;
                                if(productAmount > 0)
                                {
                                    let element1 = document.getElementById("product" + productIndex);
                                    element1.style.backgroundColor = 'green';
                                    element1.innerHTML = element1.innerHTML +   "<span class='stock'> " + String(productAmount) + " in Stock</span>";
                                } 
                                else{
                                    let element1 = document.getElementById("product" + productIndex);
                                    element1.style.backgroundColor = 'white';
                                }
                            }
                            else
                            {
                                //collect from the data
                                console.log("Product Amount " + productAmount)
                                let lilHelper = ""
                                switch (cityChoice) {
                                    case 0:
                                        lilHelper = productAmount.SpokaneValInv
                                        break
                                    case 1:
                                        lilHelper = productAmount.TriCitiesInv
                                        break
                                    case 2:
                                        lilHelper = productAmount.PhoenixInv
                                        break
                                    case 3:
                                        lilHelper = productAmount.ReddingInv
                                        break
                                    case 4:
                                        lilHelper = productAmount.SpokaneInv
                                        break
                                    case 5:
                                        lilHelper = productAmount.BoiseInv
                                        break
                                }
            
                                console.log(lilHelper)
                                let element1 = document.getElementById("product" + productIndex);


                                if(lilHelper == "n")
                                {
                                    element1.style.backgroundColor = "gray";
                                }
                                else if(lilHelper > '0'){
                                    element1.style.backgroundColor = "green"
                                    element1.innerHTML = element1.innerHTML +   "<span class='stock'> " + String(lilHelper) + " in Stock</span>";
                                }
                                else if(lilHelper == '0'){
                                    element1.style.backgroundColor = "white";
                                }   
                            }
                        }
                    }
                    else{
                        let element1 = document.getElementById("product" + productIndex);
                        element1.style.backgroundColor = 'gray';
                    }
                }
            }
        }
    };

    //resets html for product buttoms so stock is accurate
    const resetInnerHtml = async () => {

        for (let i = 0; i < productList.length; i++) {
            let element1 = document.getElementById("product" + i);
            if(productList[i].bootName != '') {
                element1.innerHTML = String(productList[i].bootName);
                element1.style.borderRadius =  "5px";
            }
        }
    }
    //mover is what is called when an button is pressed and allows the user to move around cells with arrow keys
    const mover = async (event) => {

        let direction = 0;
        if(event.key == "ArrowUp" || event.key == "w" || event.key == "W")
        {
            direction = 1;
        }
        else if(event.key == "ArrowDown" || event.key == "s" || event.key == "S")
        {
            direction = 2;
        }
        else if(event.key == "ArrowLeft" || event.key == "a" || event.key == "A")
        {
            direction = 3;
        }
        else if(event.key == "ArrowRight" || event.key == "d" || event.key == "D")
        {
            direction = 4;
        }
        else
        {
            return;
        }

        if(productPicked)
        {
            let theCollumn = productPickedIndex%collumns;
            let theRow = (productPickedIndex - theCollumn)/collumns;
            let choiceSpot = 0;
            if(direction == 1)
            {
                if(theRow == 0)
                {
                    return;
                }
                theRow = theRow - 1;
                choiceSpot = Number(theRow*collumns) + Number(theCollumn);
            }
            else if(direction == 2)
            {
                if(theRow == collumnAmmounts[theCollumn] - 1)
                {
                    return;
                }
                theRow = theRow + 1;
                choiceSpot = Number(theRow*collumns) + Number(theCollumn);
            }
            else if(direction == 3)
            {
                if(theCollumn == 0)
                {
                    return;
                }
                
                let rowOverValid = false;
                let rowOverAmount = 1;
                while(rowOverValid == false)
                {
                    if(theCollumn - rowOverAmount < 0)
                        return;
                    if(collumnAmmounts[theCollumn - rowOverAmount] > 0){
                        rowOverValid = true;
                    }
                    else{
                        rowOverAmount ++;
                    }
                }

                if(collumnAmmounts[theCollumn - rowOverAmount] < theRow + 1)
                {
                    theRow = collumnAmmounts[theCollumn - rowOverAmount] - 1;
                }

                theCollumn = theCollumn - rowOverAmount;
                choiceSpot = Number(theRow*collumns) + Number(theCollumn);
            }
            else if(direction == 4)
            {
                if(theCollumn == collumns - 1)
                {
                    return;
                }

                let rowOverValid = false;
                let rowOverAmount = 1;
                while(rowOverValid == false)
                {
                    if(theCollumn + rowOverAmount > collumns)
                        return;
                    if(collumnAmmounts[theCollumn + rowOverAmount] > 0){
                        rowOverValid = true;
                    }
                    else{
                        rowOverAmount ++;
                    }
                }

                if(collumnAmmounts[theCollumn + rowOverAmount] < theRow + 1)
                {
                    theRow = collumnAmmounts[theCollumn + rowOverAmount] - 1;
                }

                theCollumn = theCollumn + rowOverAmount;
                choiceSpot = Number(theRow*collumns) + Number(theCollumn);
            }

            productClicked(choiceSpot);
        }
        else if(sizePicked)
        {

            let startX = sizePickedX
            let startY = sizePickedY;
            let endX = startX;
            let endY = startY;
            if(direction == 1)
            {
                if(endX < 5)
                {
                    return;
                }
                if(endX == 9)
                {
                    endX -= 2;
                }
                else{
                    endX --;
                }
            }
            else if(direction == 2)
            {
                if(endX > 7)
                {
                    return;
                }
                if(endX == 7)
                {
                    endX += 2;
                }
                else{
                    endX ++;
                }
            }
            else if(direction == 3)
            {
                if(startY < 3){
                    return;
                }
                endY --;
            }
            else if(direction == 4)
            {
                if(startY > 26){
                    return;
                }
                endY ++;
            }
            sizeCellClicked(endX,endY);
        }
    };

     //city clicked is called when a city is clicked and it will change which city is used and immediately update the board
    const cityClicked = async (cityId) => {
        
        if(productFillCount != 0  || !productDone)
        {
            return;
        }
        cityChoice = cityId;
        for(let x = 0; x < cities ;x++)
        {
            let cityCell = document.getElementById("city" + x);
            if(x == cityId)
            {
                cityCell.style.backgroundColor = "Green";
            }
            else{
                cityCell.style.backgroundColor = "White";
            }
        }

        if(productPicked)
        {
            fillInFromID(productPickedIndex);
        }
        if(sizePicked)
        {
            sizeCellClicked(sizePickedX,sizePickedY);
        }

    };


    return (
        
        <Segment basic>
            <style>{`


                td, th {
                border:1px solid black;
                font-size: 12px;
                background-color: white;
                width: '200px';
                height:25%
                border-radius: 15px;
                }
                #theTable{
                    position: sticky;
                    height: 10px;
                    margin-left: auto;
                    margin-right: auto;
                    overflow-y: auto;
                    overflow-x: auto;
                }
                #cityTable{
                    position: sticky;
                    height: 10px;
                    margin-left: auto;
                    margin-right: auto;
                    overflow-y: auto;
                    overflow-x: auto;
                }
                #productTable{
                    height: 200px;
                    table-layout: fixed;
                    overflow: scroll;
                    margin-left: auto;
                    margin-right: auto;
                    overflow-y: scroll;
                    overflow-x: scroll;
                    border-radius: 15px;
                }
                #divProductTable{
                    height: '200px';
                    width: '200px';
                }
                .stock{
                    color: white;
                }

            `}</style>
            
            <Box sx={{mb: 20}}>

                <p>
                To get started push the collect data button. This will load all the current products. When it finishes a popup will say that it has completed and you will then be able to click on the boots and sizes.
                </p>
                <p>
                IMPORTANT NOTE: The data not updated unless you click the refresh data button. Which means if inventory has changed since the data was collected the new changes will not be shown on this website.
                </p>
                <p>
                Be sure to give a moment for data to load when choosing an option.
                </p>
                <p>
                You can select which locations stock you would like to look at. Also a grayed out spot means that it is not stocked.
                </p>
                <p>
                If you select a boot it will display that boots inventory quantity on the size chart. If you select a size, the boots that have that size in stock will turn green. You can also move your selection with WASD or arrow keys.
                </p>
                <Button id = "dataButton" onClick={() => startUp()}>
                    <Icon name='arrow alternate circle left'/>
                    Collect Data
                </Button>

                <table id= "cityTable">
                    <tbody>
                        <tr>
                            <th id = "city0" onClick={() => cityClicked(0)}>Spokane Valley</th>
                            <th id = "city1" onClick={() => cityClicked(1)}>Tri Cities</th>
                            <th id = "city2" onClick={() => cityClicked(2)}>Pheonix Arizona</th>
                            <th id = "city3" onClick={() => cityClicked(3)}>Redding</th>
                            <th id = "city4" onClick={() => cityClicked(4)}>Spokane</th>
                            <th id = "city5" onClick={() => cityClicked(5)}>Boise</th>
                            <th id = "city6" onClick={() => cityClicked(6)}>All</th>
                        </tr>
                    </tbody>
                </table>

                <table id = "theTable" >
                    <tbody >
                    <tr>
                    <th id = "cell3p0N" ></th>
                    <th id = "cell3p5N" >...3.5</th>
                    <th id = "cell4p0N" >.....4</th>
                    <th id = "cell4p5N" >...4.5</th>
                    <th id = "cell5p0N" >.....5</th>
                    <th id = "cell5p5N" >...5.5</th>
                    <th id = "cell6p0N" >.....6</th>
                    <th id = "cell6p5N" >...6.5</th>
                    <th id = "cell7p0N" >.....7</th>
                    <th id = "cell7p5N" >...7.5</th>
                    <th id = "cell8p0N" >.....8</th>
                    <th id = "cell8p5N" >...8.5</th>
                    <th id = "cell9p0N" >.....9</th>
                    <th id = "cell9p5N" >...9.5</th>
                    <th id = "cell10p0N" >....10</th>
                    <th id = "cell10p5N" >..10.5</th>
                    <th id = "cell11p0N" >....11</th>
                    <th id = "cell11p5N" >..11.5</th>
                    <th id = "cell12p0N" >....12</th>
                    <th id = "cell12p5N" >..12.5</th>
                    <th id = "cell13p0N" >....13</th>
                    <th id = "cell13p5N" >..13.5</th>
                    <th id = "cell14p0N" >....14</th>
                    <th id = "cell14p5N" >..14.5</th>
                    <th id = "cell15p0N" >....15</th>
                    <th id = "cell15p5N" >..15.5</th>
                    <th id = "cell16p0N" >....16</th>
                    </tr>
                    <tr>
                    <th id = "cell3p0D" >D</th>
                    <th id = "cell3p5D" onClick={() => sizeCellClicked(4,2)}>8 1/8"</th>
                    <th id = "cell4p0D" onClick={() => sizeCellClicked(4,3)}>8 1/4"</th>
                    <th id = "cell4p5D" onClick={() => sizeCellClicked(4,4)}>8 3/8"</th>
                    <th id = "cell5p0D" onClick={() => sizeCellClicked(4,5)}>8 1/2"</th>
                    <th id = "cell5p5D" onClick={() => sizeCellClicked(4,6)}>8 5/8"</th>
                    <th id = "cell6p0D" onClick={() => sizeCellClicked(4,7)}>8 3/4"</th>
                    <th id = "cell6p5D" onClick={() => sizeCellClicked(4,8)}>8 7/8"</th>
                    <th id = "cell7p0D" onClick={() => sizeCellClicked(4,9)}>9"</th>
                    <th id = "cell7p5D" onClick={() => sizeCellClicked(4,10)}>9 1/8"</th>
                    <th id = "cell8p0D" onClick={() => sizeCellClicked(4,11)}>9 1/4"</th>
                    <th id = "cell8p5D" onClick={() => sizeCellClicked(4,12)}>9 3/8"</th>
                    <th id = "cell9p0D" onClick={() => sizeCellClicked(4,13)}>9 1/2"</th>
                    <th id = "cell9p5D" onClick={() => sizeCellClicked(4,14)}>9 5/8"</th>
                    <th id = "cell10p0D" onClick={() => sizeCellClicked(4,15)}>9 3/4"</th>
                    <th id = "cell10p5D" onClick={() => sizeCellClicked(4,16)}>9 7/8"</th>
                    <th id = "cell11p0D" onClick={() => sizeCellClicked(4,17)}>10"</th>
                    <th id = "cell11p5D" onClick={() => sizeCellClicked(4,18)}>10 1/8"</th>
                    <th id = "cell12p0D" onClick={() => sizeCellClicked(4,19)}>10 1/4"</th>
                    <th id = "cell12p5D" onClick={() => sizeCellClicked(4,20)}>10 3/8"</th>
                    <th id = "cell13p0D" onClick={() => sizeCellClicked(4,21)}>10 1/2"</th>
                    <th id = "cell13p5D" onClick={() => sizeCellClicked(4,22)}>10 5/8"</th>
                    <th id = "cell14p0D" onClick={() => sizeCellClicked(4,23)}>10 3/4"</th>
                    <th id = "cell14p5D" onClick={() => sizeCellClicked(4,24)}>10 7/8"</th>
                    <th id = "cell15p0D" onClick={() => sizeCellClicked(4,25)}>11"</th>
                    <th id = "cell15p5D" onClick={() => sizeCellClicked(4,26)}>11 1/8"</th>
                    <th id = "cell16p0D" onClick={() => sizeCellClicked(4,27)}>11 1/4"</th>
                    </tr>
                    <tr>
                    <th id = "cell3p0E" >E</th>
                    <th id = "cell3p5E" onClick={() => sizeCellClicked(5,2)}>8 3/8"</th>
                    <th id = "cell4p0E" onClick={() => sizeCellClicked(5,3)}>8 1/2"</th>
                    <th id = "cell4p5E" onClick={() => sizeCellClicked(5,4)}>8 5/8"</th>
                    <th id = "cell5p0E" onClick={() => sizeCellClicked(5,5)}>8 3/4"</th>
                    <th id = "cell5p5E" onClick={() => sizeCellClicked(5,6)}>8 7/8"</th>
                    <th id = "cell6p0E" onClick={() => sizeCellClicked(5,7)}>9"</th>
                    <th id = "cell6p5E" onClick={() => sizeCellClicked(5,8)}>9 1/8"</th>
                    <th id = "cell7p0E" onClick={() => sizeCellClicked(5,9)}>9 1/4"</th>
                    <th id = "cell7p5E" onClick={() => sizeCellClicked(5,10)}>9 3/8"</th>
                    <th id = "cell8p0E" onClick={() => sizeCellClicked(5,11)}>9 1/2"</th>
                    <th id = "cell8p5E" onClick={() => sizeCellClicked(5,12)}>9 5/8"</th>
                    <th id = "cell9p0E" onClick={() => sizeCellClicked(5,13)}>9 3/4"</th>
                    <th id = "cell9p5E" onClick={() => sizeCellClicked(5,14)}>9 7/8"</th>
                    <th id = "cell10p0E" onClick={() => sizeCellClicked(5,15)}>10"</th>
                    <th id = "cell10p5E" onClick={() => sizeCellClicked(5,16)}>10 1/8"</th>
                    <th id = "cell11p0E" onClick={() => sizeCellClicked(5,17)}>10 1/4"</th>
                    <th id = "cell11p5E" onClick={() => sizeCellClicked(5,18)}>10 3/8"</th>
                    <th id = "cell12p0E" onClick={() => sizeCellClicked(5,19)}>10 1/2"</th>
                    <th id = "cell12p5E" onClick={() => sizeCellClicked(5,20)}>10 5/8"</th>
                    <th id = "cell13p0E" onClick={() => sizeCellClicked(5,21)}>10 3/4"</th>
                    <th id = "cell13p5E" onClick={() => sizeCellClicked(5,22)}>10 7/8"</th>
                    <th id = "cell14p0E" onClick={() => sizeCellClicked(5,23)}>11"</th>
                    <th id = "cell14p5E" onClick={() => sizeCellClicked(5,24)}>11 1/8"</th>
                    <th id = "cell15p0E" onClick={() => sizeCellClicked(5,25)}>11 1/4"</th>
                    <th id = "cell15p5E" onClick={() => sizeCellClicked(5,26)}>11 3/8"</th>
                    <th id = "cell16p0E" onClick={() => sizeCellClicked(5,27)}>11 1/2"</th>
                    </tr>
                    <tr>
                    <th id = "cell3p02E" >2E</th>
                    <th id = "cell3p52E" onClick={() => sizeCellClicked(6,2)}>8 5/8"</th>
                    <th id = "cell4p02E" onClick={() => sizeCellClicked(6,3)}>8 3/4"</th>
                    <th id = "cell4p52E" onClick={() => sizeCellClicked(6,4)}>8 7/8"</th>
                    <th id = "cell5p02E" onClick={() => sizeCellClicked(6,5)}>9"</th>
                    <th id = "cell5p52E" onClick={() => sizeCellClicked(6,6)}>9 1/8"</th>
                    <th id = "cell6p02E" onClick={() => sizeCellClicked(6,7)}>9 1/4"</th>
                    <th id = "cell6p52E" onClick={() => sizeCellClicked(6,8)}>9 3/8"</th>
                    <th id = "cell7p02E" onClick={() => sizeCellClicked(6,9)}>9 1/2"</th>
                    <th id = "cell7p52E" onClick={() => sizeCellClicked(6,10)}>9 5/8"</th>
                    <th id = "cell8p02E" onClick={() => sizeCellClicked(6,11)}>9 3/4"</th>
                    <th id = "cell8p52E" onClick={() => sizeCellClicked(6,12)}>9 7/8"</th>
                    <th id = "cell9p02E" onClick={() => sizeCellClicked(6,13)}>10"</th>
                    <th id = "cell9p52E" onClick={() => sizeCellClicked(6,14)}>10 1/8"</th>
                    <th id = "cell10p02E" onClick={() => sizeCellClicked(6,15)}>10 1/4"</th>
                    <th id = "cell10p52E" onClick={() => sizeCellClicked(6,16)}>10 3/8"</th>
                    <th id = "cell11p02E" onClick={() => sizeCellClicked(6,17)}>10 1/2"</th>
                    <th id = "cell11p52E" onClick={() => sizeCellClicked(6,18)}>10 5/8"</th>
                    <th id = "cell12p02E" onClick={() => sizeCellClicked(6,19)}>10 3/4"</th>
                    <th id = "cell12p52E" onClick={() => sizeCellClicked(6,20)}>10 7/8"</th>
                    <th id = "cell13p02E" onClick={() => sizeCellClicked(6,21)}>11"</th>
                    <th id = "cell13p52E" onClick={() => sizeCellClicked(6,22)}>11 1/8"</th>
                    <th id = "cell14p02E" onClick={() => sizeCellClicked(6,23)}>11 1/4"</th>
                    <th id = "cell14p52E" onClick={() => sizeCellClicked(6,24)}>11 3/8"</th>
                    <th id = "cell15p02E" onClick={() => sizeCellClicked(6,25)}>11 1/2"</th>
                    <th id = "cell15p52E" onClick={() => sizeCellClicked(6,26)}>11 5/8"</th>
                    <th id = "cell16p02E" onClick={() => sizeCellClicked(6,27)}>11 3/4"</th>
                    </tr>
                    <tr>
                    <th id = "cell3p03E" >3E</th>
                    <th id = "cell3p53E" onClick={() => sizeCellClicked(7,2)}>8 7/8"</th>
                    <th id = "cell4p03E" onClick={() => sizeCellClicked(7,3)}>9"</th>
                    <th id = "cell4p53E" onClick={() => sizeCellClicked(7,4)}>9 1/8"</th>
                    <th id = "cell5p03E" onClick={() => sizeCellClicked(7,5)}>9 1/4"</th>
                    <th id = "cell5p53E" onClick={() => sizeCellClicked(7,6)}>9 3/8"</th>
                    <th id = "cell6p03E" onClick={() => sizeCellClicked(7,7)}>9 1/2"</th>
                    <th id = "cell6p53E" onClick={() => sizeCellClicked(7,8)}>9 5/8"</th>
                    <th id = "cell7p03E" onClick={() => sizeCellClicked(7,9)}>9 3/4"</th>
                    <th id = "cell7p53E" onClick={() => sizeCellClicked(7,10)}>9 7/8"</th>
                    <th id = "cell8p03E" onClick={() => sizeCellClicked(7,11)}>10"</th>
                    <th id = "cell8p53E" onClick={() => sizeCellClicked(7,12)}>10 1/8"</th>
                    <th id = "cell9p03E" onClick={() => sizeCellClicked(7,13)}>10 1/4"</th>
                    <th id = "cell9p53E" onClick={() => sizeCellClicked(7,14)}>10 3/8"</th>
                    <th id = "cell10p03E" onClick={() => sizeCellClicked(7,15)}>10 1/2"</th>
                    <th id = "cell10p53E" onClick={() => sizeCellClicked(7,16)}>10 5/8"</th>
                    <th id = "cell11p03E" onClick={() => sizeCellClicked(7,17)}>10 3/4"</th>
                    <th id = "cell11p53E" onClick={() => sizeCellClicked(7,18)}>10 7/8"</th>
                    <th id = "cell12p03E" onClick={() => sizeCellClicked(7,19)}>11"</th>
                    <th id = "cell12p53E" onClick={() => sizeCellClicked(7,20)}>11 1/8"</th>
                    <th id = "cell13p03E" onClick={() => sizeCellClicked(7,21)}>11 1/4"</th>
                    <th id = "cell13p53E" onClick={() => sizeCellClicked(7,22)}>11 3/8"</th>
                    <th id = "cell14p03E" onClick={() => sizeCellClicked(7,23)}>11 1/2"</th>
                    <th id = "cell14p53E" onClick={() => sizeCellClicked(7,24)}>11 5/8"</th>
                    <th id = "cell15p03E" onClick={() => sizeCellClicked(7,25)}>11 3/4"</th>
                    <th id = "cell15p53E" onClick={() => sizeCellClicked(7,26)}>11 7/8"</th>
                    <th id = "cell16p03E" onClick={() => sizeCellClicked(7,27)}>12"</th>
                    </tr>
                    <tr>
                    <th id = "cell3p05E" >5E</th>
                    <th id = "cell3p55E" onClick={() => sizeCellClicked(9,2)}>9 3/8"</th>
                    <th id = "cell4p05E" onClick={() => sizeCellClicked(9,3)}>9 1/2"</th>
                    <th id = "cell4p55E" onClick={() => sizeCellClicked(9,4)}>9 5/8"</th>
                    <th id = "cell5p05E" onClick={() => sizeCellClicked(9,5)}>9 3/4"</th>
                    <th id = "cell5p55E" onClick={() => sizeCellClicked(9,6)}>9 7/8"</th>
                    <th id = "cell6p05E" onClick={() => sizeCellClicked(9,7)}>10"</th>
                    <th id = "cell6p55E" onClick={() => sizeCellClicked(9,8)}>10 1/8"</th>
                    <th id = "cell7p05E" onClick={() => sizeCellClicked(9,9)}>10 1/4"</th>
                    <th id = "cell7p55E" onClick={() => sizeCellClicked(9,10)}>10 3/8"</th>
                    <th id = "cell8p05E" onClick={() => sizeCellClicked(9,11)}>10 1/2"</th>
                    <th id = "cell8p55E" onClick={() => sizeCellClicked(9,12)}>10 5/8"</th>
                    <th id = "cell9p05E" onClick={() => sizeCellClicked(9,13)}>10 3/4"</th>
                    <th id = "cell9p55E" onClick={() => sizeCellClicked(9,14)}>10 7/8"</th>
                    <th id = "cell10p05E" onClick={() => sizeCellClicked(9,15)}>11"</th>
                    <th id = "cell10p55E" onClick={() => sizeCellClicked(9,16)}>11 1/8"</th>
                    <th id = "cell11p05E" onClick={() => sizeCellClicked(9,17)}>11 1/4"</th>
                    <th id = "cell11p55E" onClick={() => sizeCellClicked(9,18)}>11 3/8"</th>
                    <th id = "cell12p05E" onClick={() => sizeCellClicked(9,19)}>11 1/2"</th>
                    <th id = "cell12p55E" onClick={() => sizeCellClicked(9,20)}>11 5/8"</th>
                    <th id = "cell13p05E" onClick={() => sizeCellClicked(9,21)}>11 3/4"</th>
                    <th id = "cell13p55E" onClick={() => sizeCellClicked(9,22)}>11 7/8"</th>
                    <th id = "cell14p05E" onClick={() => sizeCellClicked(9,23)}>12"</th>
                    <th id = "cell14p55E" onClick={() => sizeCellClicked(9,24)}>12 1/8"</th>
                    <th id = "cell15p05E" onClick={() => sizeCellClicked(9,25)}>12 1/4"</th>
                    <th id = "cell15p55E" onClick={() => sizeCellClicked(9,26)}>12 3/8"</th>
                    <th id = "cell16p05E" onClick={() => sizeCellClicked(9,27)}>12 1/2"</th>
                    </tr>
                    </tbody>
                </table>

                <div id = "divProductTable">

                    <table id = "productTable">
                        <tbody >
                        <tr>
                        <th id = "product0" onClick={() => productClicked(0)}> </th>
                        <th id = "product1" onClick={() => productClicked(1)}> </th>
                        <th id = "product2" onClick={() => productClicked(2)}> </th>
                        <th id = "product3" onClick={() => productClicked(3)}> </th>
                        <th id = "product4" onClick={() => productClicked(4)}> </th>
                        <th id = "product5" onClick={() => productClicked(5)}> </th>
                        <th id = "product6" onClick={() => productClicked(6)}> </th>
                        <th id = "product7" onClick={() => productClicked(7)}> </th>
                        <th id = "product8" onClick={() => productClicked(8)}> </th>
                        <th id = "product9" onClick={() => productClicked(9)}> </th>
                        <th id = "product10" onClick={() => productClicked(10)}> </th>
                        <th id = "product11" onClick={() => productClicked(11)}> </th>
                        <th id = "product12" onClick={() => productClicked(12)}> </th>
                        <th id = "product13" onClick={() => productClicked(13)}> </th>
                        <th id = "product14" onClick={() => productClicked(14)}> </th>
                        <th id = "product15" onClick={() => productClicked(15)}> </th>
                        </tr>
                        <tr>
                        <th id = "product16" onClick={() => productClicked(16)}> </th>
                        <th id = "product17" onClick={() => productClicked(17)}> </th>
                        <th id = "product18" onClick={() => productClicked(18)}> </th>
                        <th id = "product19" onClick={() => productClicked(19)}> </th>
                        <th id = "product20" onClick={() => productClicked(20)}> </th>
                        <th id = "product21" onClick={() => productClicked(21)}> </th>
                        <th id = "product22" onClick={() => productClicked(22)}> </th>
                        <th id = "product23" onClick={() => productClicked(23)}> </th>
                        <th id = "product24" onClick={() => productClicked(24)}> </th>
                        <th id = "product25" onClick={() => productClicked(25)}> </th>
                        <th id = "product26" onClick={() => productClicked(26)}> </th>
                        <th id = "product27" onClick={() => productClicked(27)}> </th>
                        <th id = "product28" onClick={() => productClicked(28)}> </th>
                        <th id = "product29" onClick={() => productClicked(29)}> </th>
                        <th id = "product30" onClick={() => productClicked(30)}> </th>
                        <th id = "product31" onClick={() => productClicked(31)}> </th>
                        </tr>
                        <tr>
                        <th id = "product32" onClick={() => productClicked(32)}> </th>
                        <th id = "product33" onClick={() => productClicked(33)}> </th>
                        <th id = "product34" onClick={() => productClicked(34)}> </th>
                        <th id = "product35" onClick={() => productClicked(35)}> </th>
                        <th id = "product36" onClick={() => productClicked(36)}> </th>
                        <th id = "product37" onClick={() => productClicked(37)}> </th>
                        <th id = "product38" onClick={() => productClicked(38)}> </th>
                        <th id = "product39" onClick={() => productClicked(39)}> </th>
                        <th id = "product40" onClick={() => productClicked(40)}> </th>
                        <th id = "product41" onClick={() => productClicked(41)}> </th>
                        <th id = "product42" onClick={() => productClicked(42)}> </th>
                        <th id = "product43" onClick={() => productClicked(43)}> </th>
                        <th id = "product44" onClick={() => productClicked(44)}> </th>
                        <th id = "product45" onClick={() => productClicked(45)}> </th>
                        <th id = "product46" onClick={() => productClicked(46)}> </th>
                        <th id = "product47" onClick={() => productClicked(47)}> </th>
                        </tr>
                        <tr>
                        <th id = "product48" onClick={() => productClicked(48)}> </th>
                        <th id = "product49" onClick={() => productClicked(49)}> </th>
                        <th id = "product50" onClick={() => productClicked(50)}> </th>
                        <th id = "product51" onClick={() => productClicked(51)}> </th>
                        <th id = "product52" onClick={() => productClicked(52)}> </th>
                        <th id = "product53" onClick={() => productClicked(53)}> </th>
                        <th id = "product54" onClick={() => productClicked(54)}> </th>
                        <th id = "product55" onClick={() => productClicked(55)}> </th>
                        <th id = "product56" onClick={() => productClicked(56)}> </th>
                        <th id = "product57" onClick={() => productClicked(57)}> </th>
                        <th id = "product58" onClick={() => productClicked(58)}> </th>
                        <th id = "product59" onClick={() => productClicked(59)}> </th>
                        <th id = "product60" onClick={() => productClicked(60)}> </th>
                        <th id = "product61" onClick={() => productClicked(61)}> </th>
                        <th id = "product62" onClick={() => productClicked(62)}> </th>
                        <th id = "product63" onClick={() => productClicked(63)}> </th>
                        </tr>
                        <tr>
                        <th id = "product64" onClick={() => productClicked(64)}> </th>
                        <th id = "product65" onClick={() => productClicked(65)}> </th>
                        <th id = "product66" onClick={() => productClicked(66)}> </th>
                        <th id = "product67" onClick={() => productClicked(67)}> </th>
                        <th id = "product68" onClick={() => productClicked(68)}> </th>
                        <th id = "product69" onClick={() => productClicked(69)}> </th>
                        <th id = "product70" onClick={() => productClicked(70)}> </th>
                        <th id = "product71" onClick={() => productClicked(71)}> </th>
                        <th id = "product72" onClick={() => productClicked(72)}> </th>
                        <th id = "product73" onClick={() => productClicked(73)}> </th>
                        <th id = "product74" onClick={() => productClicked(74)}> </th>
                        <th id = "product75" onClick={() => productClicked(75)}> </th>
                        <th id = "product76" onClick={() => productClicked(76)}> </th>
                        <th id = "product77" onClick={() => productClicked(77)}> </th>
                        <th id = "product78" onClick={() => productClicked(78)}> </th>
                        <th id = "product79" onClick={() => productClicked(79)}> </th>
                        </tr>
                        <tr>
                        <th id = "product80" onClick={() => productClicked(80)}> </th>
                        <th id = "product81" onClick={() => productClicked(81)}> </th>
                        <th id = "product82" onClick={() => productClicked(82)}> </th>
                        <th id = "product83" onClick={() => productClicked(83)}> </th>
                        <th id = "product84" onClick={() => productClicked(84)}> </th>
                        <th id = "product85" onClick={() => productClicked(85)}> </th>
                        <th id = "product86" onClick={() => productClicked(86)}> </th>
                        <th id = "product87" onClick={() => productClicked(87)}> </th>
                        <th id = "product88" onClick={() => productClicked(88)}> </th>
                        <th id = "product89" onClick={() => productClicked(89)}> </th>
                        <th id = "product90" onClick={() => productClicked(90)}> </th>
                        <th id = "product91" onClick={() => productClicked(91)}> </th>
                        <th id = "product92" onClick={() => productClicked(92)}> </th>
                        <th id = "product93" onClick={() => productClicked(93)}> </th>
                        <th id = "product94" onClick={() => productClicked(94)}> </th>
                        <th id = "product95" onClick={() => productClicked(95)}> </th>
                        </tr>
                        <tr>
                        <th id = "product96" onClick={() => productClicked(96)}> </th>
                        <th id = "product97" onClick={() => productClicked(97)}> </th>
                        <th id = "product98" onClick={() => productClicked(98)}> </th>
                        <th id = "product99" onClick={() => productClicked(99)}> </th>
                        <th id = "product100" onClick={() => productClicked(100)}> </th>
                        <th id = "product101" onClick={() => productClicked(101)}> </th>
                        <th id = "product102" onClick={() => productClicked(102)}> </th>
                        <th id = "product103" onClick={() => productClicked(103)}> </th>
                        <th id = "product104" onClick={() => productClicked(104)}> </th>
                        <th id = "product105" onClick={() => productClicked(105)}> </th>
                        <th id = "product106" onClick={() => productClicked(106)}> </th>
                        <th id = "product107" onClick={() => productClicked(107)}> </th>
                        <th id = "product108" onClick={() => productClicked(108)}> </th>
                        <th id = "product109" onClick={() => productClicked(109)}> </th>
                        <th id = "product110" onClick={() => productClicked(110)}> </th>
                        <th id = "product111" onClick={() => productClicked(111)}> </th>
                        </tr>
                        <tr>
                        <th id = "product112" onClick={() => productClicked(112)}> </th>
                        <th id = "product113" onClick={() => productClicked(113)}> </th>
                        <th id = "product114" onClick={() => productClicked(114)}> </th>
                        <th id = "product115" onClick={() => productClicked(115)}> </th>
                        <th id = "product116" onClick={() => productClicked(116)}> </th>
                        <th id = "product117" onClick={() => productClicked(117)}> </th>
                        <th id = "product118" onClick={() => productClicked(118)}> </th>
                        <th id = "product119" onClick={() => productClicked(119)}> </th>
                        <th id = "product120" onClick={() => productClicked(120)}> </th>
                        <th id = "product121" onClick={() => productClicked(121)}> </th>
                        <th id = "product122" onClick={() => productClicked(122)}> </th>
                        <th id = "product123" onClick={() => productClicked(123)}> </th>
                        <th id = "product124" onClick={() => productClicked(124)}> </th>
                        <th id = "product125" onClick={() => productClicked(125)}> </th>
                        <th id = "product126" onClick={() => productClicked(126)}> </th>
                        <th id = "product127" onClick={() => productClicked(127)}> </th>
                        </tr>
                        <tr>
                        <th id = "product128" onClick={() => productClicked(128)}> </th>
                        <th id = "product129" onClick={() => productClicked(129)}> </th>
                        <th id = "product130" onClick={() => productClicked(130)}> </th>
                        <th id = "product131" onClick={() => productClicked(131)}> </th>
                        <th id = "product132" onClick={() => productClicked(132)}> </th>
                        <th id = "product133" onClick={() => productClicked(133)}> </th>
                        <th id = "product134" onClick={() => productClicked(134)}> </th>
                        <th id = "product135" onClick={() => productClicked(135)}> </th>
                        <th id = "product136" onClick={() => productClicked(136)}> </th>
                        <th id = "product137" onClick={() => productClicked(137)}> </th>
                        <th id = "product138" onClick={() => productClicked(138)}> </th>
                        <th id = "product139" onClick={() => productClicked(139)}> </th>
                        <th id = "product140" onClick={() => productClicked(140)}> </th>
                        <th id = "product141" onClick={() => productClicked(141)}> </th>
                        <th id = "product142" onClick={() => productClicked(142)}> </th>
                        <th id = "product143" onClick={() => productClicked(143)}> </th>
                        </tr>
                        <tr>
                        <th id = "product144" onClick={() => productClicked(144)}> </th>
                        <th id = "product145" onClick={() => productClicked(145)}> </th>
                        <th id = "product146" onClick={() => productClicked(146)}> </th>
                        <th id = "product147" onClick={() => productClicked(147)}> </th>
                        <th id = "product148" onClick={() => productClicked(148)}> </th>
                        <th id = "product149" onClick={() => productClicked(149)}> </th>
                        <th id = "product150" onClick={() => productClicked(150)}> </th>
                        <th id = "product151" onClick={() => productClicked(151)}> </th>
                        <th id = "product152" onClick={() => productClicked(152)}> </th>
                        <th id = "product153" onClick={() => productClicked(153)}> </th>
                        <th id = "product154" onClick={() => productClicked(154)}> </th>
                        <th id = "product155" onClick={() => productClicked(155)}> </th>
                        <th id = "product156" onClick={() => productClicked(156)}> </th>
                        <th id = "product157" onClick={() => productClicked(157)}> </th>
                        <th id = "product158" onClick={() => productClicked(158)}> </th>
                        <th id = "product159" onClick={() => productClicked(159)}> </th>
                        </tr>
                        <tr>
                        <th id = "product160" onClick={() => productClicked(160)}> </th>
                        <th id = "product161" onClick={() => productClicked(161)}> </th>
                        <th id = "product162" onClick={() => productClicked(162)}> </th>
                        <th id = "product163" onClick={() => productClicked(163)}> </th>
                        <th id = "product164" onClick={() => productClicked(164)}> </th>
                        <th id = "product165" onClick={() => productClicked(165)}> </th>
                        <th id = "product166" onClick={() => productClicked(166)}> </th>
                        <th id = "product167" onClick={() => productClicked(167)}> </th>
                        <th id = "product168" onClick={() => productClicked(168)}> </th>
                        <th id = "product169" onClick={() => productClicked(169)}> </th>
                        <th id = "product170" onClick={() => productClicked(170)}> </th>
                        <th id = "product171" onClick={() => productClicked(171)}> </th>
                        <th id = "product172" onClick={() => productClicked(172)}> </th>
                        <th id = "product173" onClick={() => productClicked(173)}> </th>
                        <th id = "product174" onClick={() => productClicked(174)}> </th>
                        <th id = "product175" onClick={() => productClicked(175)}> </th>
                        </tr>
                        <tr>
                        <th id = "product176" onClick={() => productClicked(176)}> </th>
                        <th id = "product177" onClick={() => productClicked(177)}> </th>
                        <th id = "product178" onClick={() => productClicked(178)}> </th>
                        <th id = "product179" onClick={() => productClicked(179)}> </th>
                        <th id = "product180" onClick={() => productClicked(180)}> </th>
                        <th id = "product181" onClick={() => productClicked(181)}> </th>
                        <th id = "product182" onClick={() => productClicked(182)}> </th>
                        <th id = "product183" onClick={() => productClicked(183)}> </th>
                        <th id = "product184" onClick={() => productClicked(184)}> </th>
                        <th id = "product185" onClick={() => productClicked(185)}> </th>
                        <th id = "product186" onClick={() => productClicked(186)}> </th>
                        <th id = "product187" onClick={() => productClicked(187)}> </th>
                        <th id = "product188" onClick={() => productClicked(188)}> </th>
                        <th id = "product189" onClick={() => productClicked(189)}> </th>
                        <th id = "product190" onClick={() => productClicked(190)}> </th>
                        <th id = "product191" onClick={() => productClicked(191)}> </th>
                        </tr>
                        <tr>
                        <th id = "product192" onClick={() => productClicked(192)}> </th>
                        <th id = "product193" onClick={() => productClicked(193)}> </th>
                        <th id = "product194" onClick={() => productClicked(194)}> </th>
                        <th id = "product195" onClick={() => productClicked(195)}> </th>
                        <th id = "product196" onClick={() => productClicked(196)}> </th>
                        <th id = "product197" onClick={() => productClicked(197)}> </th>
                        <th id = "product198" onClick={() => productClicked(198)}> </th>
                        <th id = "product199" onClick={() => productClicked(199)}> </th>
                        <th id = "product200" onClick={() => productClicked(200)}> </th>
                        <th id = "product201" onClick={() => productClicked(201)}> </th>
                        <th id = "product202" onClick={() => productClicked(202)}> </th>
                        <th id = "product203" onClick={() => productClicked(203)}> </th>
                        <th id = "product204" onClick={() => productClicked(204)}> </th>
                        <th id = "product205" onClick={() => productClicked(205)}> </th>
                        <th id = "product206" onClick={() => productClicked(206)}> </th>
                        <th id = "product207" onClick={() => productClicked(207)}> </th>
                        </tr>
                        <tr>
                        <th id = "product208" onClick={() => productClicked(208)}> </th>
                        <th id = "product209" onClick={() => productClicked(209)}> </th>
                        <th id = "product210" onClick={() => productClicked(210)}> </th>
                        <th id = "product211" onClick={() => productClicked(211)}> </th>
                        <th id = "product212" onClick={() => productClicked(212)}> </th>
                        <th id = "product213" onClick={() => productClicked(213)}> </th>
                        <th id = "product214" onClick={() => productClicked(214)}> </th>
                        <th id = "product215" onClick={() => productClicked(215)}> </th>
                        <th id = "product216" onClick={() => productClicked(216)}> </th>
                        <th id = "product217" onClick={() => productClicked(217)}> </th>
                        <th id = "product218" onClick={() => productClicked(218)}> </th>
                        <th id = "product219" onClick={() => productClicked(219)}> </th>
                        <th id = "product220" onClick={() => productClicked(220)}> </th>
                        <th id = "product221" onClick={() => productClicked(221)}> </th>
                        <th id = "product222" onClick={() => productClicked(222)}> </th>
                        <th id = "product223" onClick={() => productClicked(223)}> </th>
                        </tr>
                        <tr>
                        <th id = "product224" onClick={() => productClicked(224)}> </th>
                        <th id = "product225" onClick={() => productClicked(225)}> </th>
                        <th id = "product226" onClick={() => productClicked(226)}> </th>
                        <th id = "product227" onClick={() => productClicked(227)}> </th>
                        <th id = "product228" onClick={() => productClicked(228)}> </th>
                        <th id = "product229" onClick={() => productClicked(229)}> </th>
                        <th id = "product230" onClick={() => productClicked(230)}> </th>
                        <th id = "product231" onClick={() => productClicked(231)}> </th>
                        <th id = "product232" onClick={() => productClicked(232)}> </th>
                        <th id = "product233" onClick={() => productClicked(233)}> </th>
                        <th id = "product234" onClick={() => productClicked(234)}> </th>
                        <th id = "product235" onClick={() => productClicked(235)}> </th>
                        <th id = "product236" onClick={() => productClicked(236)}> </th>
                        <th id = "product237" onClick={() => productClicked(237)}> </th>
                        <th id = "product238" onClick={() => productClicked(238)}> </th>
                        <th id = "product239" onClick={() => productClicked(239)}> </th>
                        </tr>
                        <tr>
                        <th id = "product240" onClick={() => productClicked(240)}> </th>
                        <th id = "product241" onClick={() => productClicked(241)}> </th>
                        <th id = "product242" onClick={() => productClicked(242)}> </th>
                        <th id = "product243" onClick={() => productClicked(243)}> </th>
                        <th id = "product244" onClick={() => productClicked(244)}> </th>
                        <th id = "product245" onClick={() => productClicked(245)}> </th>
                        <th id = "product246" onClick={() => productClicked(246)}> </th>
                        <th id = "product247" onClick={() => productClicked(247)}> </th>
                        <th id = "product248" onClick={() => productClicked(248)}> </th>
                        <th id = "product249" onClick={() => productClicked(249)}> </th>
                        <th id = "product250" onClick={() => productClicked(250)}> </th>
                        <th id = "product251" onClick={() => productClicked(251)}> </th>
                        <th id = "product252" onClick={() => productClicked(252)}> </th>
                        <th id = "product253" onClick={() => productClicked(253)}> </th>
                        <th id = "product254" onClick={() => productClicked(254)}> </th>
                        <th id = "product255" onClick={() => productClicked(255)}> </th>
                        </tr>
                        <tr>
                        <th id = "product256" onClick={() => productClicked(256)}> </th>
                        <th id = "product257" onClick={() => productClicked(257)}> </th>
                        <th id = "product258" onClick={() => productClicked(258)}> </th>
                        <th id = "product259" onClick={() => productClicked(259)}> </th>
                        <th id = "product260" onClick={() => productClicked(260)}> </th>
                        <th id = "product261" onClick={() => productClicked(261)}> </th>
                        <th id = "product262" onClick={() => productClicked(262)}> </th>
                        <th id = "product263" onClick={() => productClicked(263)}> </th>
                        <th id = "product264" onClick={() => productClicked(264)}> </th>
                        <th id = "product265" onClick={() => productClicked(265)}> </th>
                        <th id = "product266" onClick={() => productClicked(266)}> </th>
                        <th id = "product267" onClick={() => productClicked(267)}> </th>
                        <th id = "product268" onClick={() => productClicked(268)}> </th>
                        <th id = "product269" onClick={() => productClicked(269)}> </th>
                        <th id = "product270" onClick={() => productClicked(270)}> </th>
                        <th id = "product271" onClick={() => productClicked(271)}> </th>
                        </tr>
                        <tr>
                        <th id = "product272" onClick={() => productClicked(272)}> </th>
                        <th id = "product273" onClick={() => productClicked(273)}> </th>
                        <th id = "product274" onClick={() => productClicked(274)}> </th>
                        <th id = "product275" onClick={() => productClicked(275)}> </th>
                        <th id = "product276" onClick={() => productClicked(276)}> </th>
                        <th id = "product277" onClick={() => productClicked(277)}> </th>
                        <th id = "product278" onClick={() => productClicked(278)}> </th>
                        <th id = "product279" onClick={() => productClicked(279)}> </th>
                        <th id = "product280" onClick={() => productClicked(280)}> </th>
                        <th id = "product281" onClick={() => productClicked(281)}> </th>
                        <th id = "product282" onClick={() => productClicked(282)}> </th>
                        <th id = "product283" onClick={() => productClicked(283)}> </th>
                        <th id = "product284" onClick={() => productClicked(284)}> </th>
                        <th id = "product285" onClick={() => productClicked(285)}> </th>
                        <th id = "product286" onClick={() => productClicked(286)}> </th>
                        <th id = "product287" onClick={() => productClicked(287)}> </th>
                        </tr>
                        <tr>
                        <th id = "product288" onClick={() => productClicked(288)}> </th>
                        <th id = "product289" onClick={() => productClicked(289)}> </th>
                        <th id = "product290" onClick={() => productClicked(290)}> </th>
                        <th id = "product291" onClick={() => productClicked(291)}> </th>
                        <th id = "product292" onClick={() => productClicked(292)}> </th>
                        <th id = "product293" onClick={() => productClicked(293)}> </th>
                        <th id = "product294" onClick={() => productClicked(294)}> </th>
                        <th id = "product295" onClick={() => productClicked(295)}> </th>
                        <th id = "product296" onClick={() => productClicked(296)}> </th>
                        <th id = "product297" onClick={() => productClicked(297)}> </th>
                        <th id = "product298" onClick={() => productClicked(298)}> </th>
                        <th id = "product299" onClick={() => productClicked(299)}> </th>
                        <th id = "product300" onClick={() => productClicked(300)}> </th>
                        <th id = "product301" onClick={() => productClicked(301)}> </th>
                        <th id = "product302" onClick={() => productClicked(302)}> </th>
                        <th id = "product303" onClick={() => productClicked(303)}> </th>
                        </tr>
                        <tr>
                        <th id = "product304" onClick={() => productClicked(304)}> </th>
                        <th id = "product305" onClick={() => productClicked(305)}> </th>
                        <th id = "product306" onClick={() => productClicked(306)}> </th>
                        <th id = "product307" onClick={() => productClicked(307)}> </th>
                        <th id = "product308" onClick={() => productClicked(308)}> </th>
                        <th id = "product309" onClick={() => productClicked(309)}> </th>
                        <th id = "product310" onClick={() => productClicked(310)}> </th>
                        <th id = "product311" onClick={() => productClicked(311)}> </th>
                        <th id = "product312" onClick={() => productClicked(312)}> </th>
                        <th id = "product313" onClick={() => productClicked(313)}> </th>
                        <th id = "product314" onClick={() => productClicked(314)}> </th>
                        <th id = "product315" onClick={() => productClicked(315)}> </th>
                        <th id = "product316" onClick={() => productClicked(316)}> </th>
                        <th id = "product317" onClick={() => productClicked(317)}> </th>
                        <th id = "product318" onClick={() => productClicked(318)}> </th>
                        <th id = "product319" onClick={() => productClicked(319)}> </th>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Box>
        </Segment>
    );
};