//https://main.d1ydbvhz214kug.amplifyapp.com/

import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import 'semantic-ui-css/semantic.min.css';

import {
    Button,
    Header,
    Image,
} from 'semantic-ui-react';
import {Box} from 'theme-ui';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './index.css';
import {Login} from './login';
import {Footer} from './other/footer';
import JKLogo from './images/JK-logo.png';
import {Lookup} from './other/lookup';

const App = () => {
    const [user, setUser] = useState({});
    const [reprintBy, setReprintBy] = useState('');
    const [credential, setCredential] = useState('');

    const startMe = true;


    const handleSignOut = () => {
        setUser({});
        
        setCredential('');
        toast.success('We are signed out!', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        });
        if(reprintBy)//isn't needed but setReprintBy is so this stops the compile warning
        {

        }
    };





    return (
        <div
        style={{
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column',
            justifyContent: 'space-between',
            textAlign: 'center',
            background: '#84F597' // this is where the background color comes from
        }}
    >
            {Object.keys(user).length === 0 ? (
                <Login user={user} setUser={setUser} setCredential={setCredential} setReprintBy={setReprintBy}/>
            ) : (
                <Box>
                    <ToastContainer/>
                    <Image src={JKLogo} size='small' style={{margin: '0 auto'}}/>
                    <Header as='h1' textAlign='center'>
                        JK Boots Fast Stock Lookup
                    </Header>
                    <Box sx={{mb: 20}}>
                        <Button color='red' size='large' onClick={() => handleSignOut()}>
                            Sign Out
                        </Button>
                    </Box>
                    <br/>
                    <Box sx={{mb: 20}}>
                    </Box>
                    {startMe ? (
                        <Lookup
                        credential={credential}/>
                    ) : null
                    }
                </Box>
            )}
            <Footer/>
        </div>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);

